<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                      <b-row>
                        <!-- ================================ -->
                        <b-col class="col-sm-12 col-md-12">
                            <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="6"
                                    label-for="org_id"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.org_id"
                                    :options="orgList"
                                    id="org_id"
                                    :disabled="formData.org_id != ''"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <!-- ================================ -->
                            <b-col class="col-sm-12 col-md-12">
                            <ValidationProvider name="Land & Soil Type" vid="type_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="6"
                                    label-for="type_id"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('VirtualMuseum.configuration.soil_type')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="formData.type_id"
                                    :options="soilTypeList"
                                    id="type_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>

                        <b-col class="col-sm-12 col-md-12">
                          <ValidationProvider name="Description (En)" vid="description_en" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="6"
                                label-for="description_en"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('vm_museum_management.description_en') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="description_en"
                                plain
                                v-model="formData.description_en"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col class="col-sm-12 col-md-12">
                          <ValidationProvider name="Description (Bn)" vid="description_bn" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="6"
                                label-for="description_bn"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('vm_museum_management.description_bn') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="description_bn"
                                plain
                                v-model="formData.description_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <div class="col mt-2 text-right">
                          <b-button type="submit" variant="primary">{{ saveBtnName}}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </b-row>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
// import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { soilInfoStore, soilInfoUpdate } from '../../api/routes'
export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.formData.org_id = this.$store.state.dataFilters.orgId
    if (this.id) {
      const tmp = this.getLandSoilData()
      this.formData = tmp
    }
  },
  mounted () {
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      virtualMuseumServiceBaseUrl: virtualMuseumServiceBaseUrl,
      formData: {
        org_id: 0,
        type_id: 0,
        description_en: '',
        description_bn: ''
      }
    }
  },
  computed: {
    orgList: function () {
          const objectData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
          return objectData.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn }
              } else {
                  return { value: obj.value, text: obj.text_en }
              }
          })
    },
    soilTypeList: function () {
              const soilTypeData = this.$store.state.VirtualMuseum.commonObj.soilTypeList.filter(item => item.status === 1)
              return soilTypeData.map(item => {
                  if (this.$i18n.locale === 'bn') {
                      return { value: item.value, text: item.text_bn }
                  } else {
                      return { value: item.value, text: item.text_en }
                  }
              })
    }
  },
  watch: {
  },
  methods: {
    getLandSoilData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
      if (this.id) {
        // this.formData.append('_method', 'PUT')
        result = await RestApi.putData(virtualMuseumServiceBaseUrl, `${soilInfoUpdate}/${this.id}`, this.formData, config)
      } else {
        result = await RestApi.postData(virtualMuseumServiceBaseUrl, soilInfoStore, this.formData, config)
      }
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      this.loading = false

      if (result.success) {
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
