
<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('VirtualMuseum.configuration.land_and_soil_characteristic') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
                        <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                      </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                          {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                          <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                          <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(image)="data">
                                          <b-img width='100px' :src="virtualMuseumServiceBaseUrl+'storage/uploads/crop/original/'+data.item.crop_image" fluid alt="Right Logo"></b-img>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" :title="$t('globalTrans.edit')" class="btn_table_action table_action_edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                                            <a href="javascript:" :title="$t('globalTrans.active')" class="btn_table_action table_action_status" v-if="data.item.status == 1" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                                            <a href="javascript:" :title="$t('globalTrans.inactive')" class="btn_table_action table_action_toggle" v-else @click="remove(data.item)"><i class="fas fa-toggle-off"></i></a>

                                        </template>
                                    </b-table>
                                    <b-pagination
                                      align="center"
                                      v-model="pagination.currentPage"
                                      :per-page="pagination.perPage"
                                      :total-rows="pagination.totalRows"
                                      @input="searchData"
                                    />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
         <!-- <b-modal id="modal-5" size="lg" :title="$t('VirtualMuseum.subsidy_circular')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="item" :key="item"></Details>
        </b-modal> -->
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { soilInfoList, soilInfoToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form
  },
  data () {
    return {
      search: {
        org_id: (this.$store.state.Auth.activeRoleId === 1) ? 0 : this.$store.state.Auth.authUser.org_id
      },
      item: '',
      virtualMuseumServiceBaseUrl: virtualMuseumServiceBaseUrl
    }
  },
  mounted () {
  },
  computed: {
    formTitle () {
      return (this.editItemId === 0) ? this.$t('vm_museum_management.soil_information') + ' ' + this.$t('globalTrans.entry') : this.$t('vm_museum_management.soil_information') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('globalTrans.organization'), class: 'text-center' },
          { label: this.$t('VirtualMuseum.configuration.soil_type'), class: 'text-center' },
          { label: this.$t('vm_museum_management.description'), class: 'text-center' },
          { label: this.$t('VirtualMuseum.configuration.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center fixed-width' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name_bn' },
          { key: 'soil_type_bn' },
          { key: 'description_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_name_en' },
          { key: 'soil_type_en' },
          { key: 'description_en' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.item = item
    },
    remove (item) {
       this.changeStatus(virtualMuseumServiceBaseUrl, soilInfoToggleStatus, item)
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(virtualMuseumServiceBaseUrl, soilInfoList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const soilTypeList = this.$store.state.VirtualMuseum.commonObj.soilTypeList.filter(item => item.status === 1)
      const orgList = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      return data.map(item => {
        const soilTypeObject = soilTypeList.find(sinCrType => sinCrType.value === item.type_id)
        const orgObject = orgList.find(sinOrg => sinOrg.value === item.org_id)
        const soilType = {
          soil_type_en: soilTypeObject !== undefined ? soilTypeObject.text_en : '',
          soil_type_bn: soilTypeObject !== undefined ? soilTypeObject.text_bn : ''
          }
        const org = {
          org_name_en: orgObject !== undefined ? orgObject.text_en : '',
          org_name_bn: orgObject !== undefined ? orgObject.text_bn : ''
          }
        return Object.assign({}, item, soilType, org)
      })
    }
  }
}
</script>
<style lang="scss">
  .fixed-width {
    width: 180px !important;
  }
</style>
